import React from 'react';

import SEO from '../components/seo';

const Home = () => (
	<section className="clients">
		<SEO
			title="Clients"
			description=""
		/>

		<h2>Clients</h2>

		<div className="clients-grid">
			<div>
				<img src="arttech.png" alt="Fondation ArtTech" />
				<h3>Fondation ArtTech</h3>
			</div>

			<div>
				<img src="unil.png" alt="Université de Lausanne" />
				<h3>Université de Lausanne</h3>
			</div>

			<div>
				<img src="collart.jpg" alt="Association Paul Collart au Proche-Orient" />
				<h3>Association Paul Collart au Proche-Orient</h3>
			</div>

			<div>
				<img src="p10.png" alt="Plateforme 10 - Canton de Vaud" />
				<h3>Plateforme 10 - Canton de Vaud</h3>
			</div>

			<div>
				<img src="berinfor.png" alt="Berinfor AG" />
				<h3>Berinfor AG</h3>
			</div>
		</div>
	</section>
);

export default Home;
